import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Content from '../components/Content'
import Hero from '../components/Hero'
import Highlight, { isHighlightDataValid } from '../components/Highlight'
import StickySection from '../components/StickySection'
import Separator from '../components/Separator'

export const query = graphql`
  query ProductQuery {
    prismic {
      data: product(uid: "product", lang: "en-gb") {
        metaTitle: meta_title
        metaDescription: meta_description
        metaImage: meta_image
        heroLabel: hero_label
        heroTitle: hero_title
        heroBody: hero_body
        heroImage: hero_image
        heroImageSharp: hero_imageSharp {
          childImageSharp {
            fluid(maxWidth: 1900) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        intro
        bodySection: body_section {
          title
          body
          icon
        }
        highlightTitle: highlight_title
        highlightBody: highlight_body
        highlight_image
        highlightImageSharp: highlight_imageSharp {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        highlightCtaLabel: highlight_cta_label
        highlightCta: highlight_cta {
          _linkType
          ... on PRISMIC__ExternalLink {
            url
          }
          ... on PRISMIC__Document {
            meta: _meta {
              id
              uid
              type
            }
          }
        }
      }
    }
  }
`

const AboutPage = ({
  data: {
    prismic: { data },
  },
}) => {
  return (
    <Layout {...data}>
      <Hero {...data} />
      <Separator />
      <Content>
        <StickySection {...data} />
        <Separator />
        <Highlight {...data} />
        {isHighlightDataValid(data) && <Separator />}
      </Content>
    </Layout>
  )
}

// AboutPage.propTypes = {
//   data: PropTypes.shape({
//     prismic: PropTypes.shape({
//       data: PropTypes.shape({}).isRequired,
//     }).isRequired,
//   }).isRequired,
// }

export default AboutPage
